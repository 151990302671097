/* eslint-disable*/
import VueStoreService from './VueStoreService'
const UserReportFiltersService = {
  async getFilters(user_id, store) {
    var resp = await VueStoreService.get('user-report-filters/' + user_id, store)
    return resp
  },
  async saveFilters(data, store) {
    var resp = await VueStoreService.post('user-report-filters', data, store)
    return resp
  },
  async updateFilters(user_id, data, store) {
    var resp = await VueStoreService.put('user-report-filters/' + user_id, data, store)
    return resp
  },
}

export default UserReportFiltersService
